<template>
    <el-dialog
        class="add-program-goal"
        :title="title"
        visible
        @close="handleClose"
        :close-on-click-modal="false"
        width="599px"
    >
        <div slot="title" class="header-title" v-if="programGoalTitle">
            <span class="title-name"
                ><i
                    class="el-icon-warning"
                    style="color: #e6a23c; margin-right: 10px"
                ></i
            ></span>
            <span class="title-age"
                >您绑定的项目还未添加当月交付目标，请及时添加</span
            >
        </div>
        <div
            class="sub-title ell"
            v-if="businessType == 'project'"
            :title="`项目名称：${form.projectName}`"
        >
            项目名称：{{ form.projectName ? form.projectName.replace("$#$", "-") : "" }}
        </div>

        <el-form
            :model="form"
            :rules="rules"
            ref="goalForm"
            label-width="110px"
        >
            <el-form-item label="交付时间" prop="monthly">
                <el-date-picker
                    class="input-cell"
                    v-model="form.monthly"
                    type="month"
                    :disabled="form.type === 'edit'"
                    :default-time="['00:00:00', '23:59:59']"
                    format="yyyy 年 MM 月"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :picker-options="pickerOptions"
                    placeholder="选择交付时间"
                >
                </el-date-picker>
            </el-form-item>

            <h2>交付目标</h2>
            <div class="form-wrap">
                <el-form-item label="月推荐" prop="goalOfRecommandation">
                    <el-input
                        v-model="form.goalOfRecommandation"
                        placeholder="输入月推荐交付目标"
                        class="input-cell"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="月面试" prop="goalOfInterview">
                    <el-input
                        v-model="form.goalOfInterview"
                        placeholder="输入月面试交付目标"
                        class="input-cell"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="月offers" prop="goalOfOffer">
                    <el-input
                        v-model="form.goalOfOffer"
                        placeholder="输入月offers交付目标"
                        class="input-cell"
                    >
                    </el-input>
                </el-form-item>
            </div>

            <h2 v-if="businessType == 'project' && goalParams.type == 'add'">交付信息</h2>
            <div class="form-wrap" v-if="businessType == 'project' && goalParams.type == 'add'">
                <el-form-item label="HC数量" prop="headCount">
                    <el-input
                        v-model="form.headCount"
                        placeholder="输入HC数量"
                        class="input-cell"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="乐观程度" prop="optimisticDegree">
                    <el-select
                        v-model="form.optimisticDegree"
                        placeholder="请选择乐观程度"
                    >
                        <el-option label="请选择" value="0"></el-option>
                        <el-option
                            v-for="item in optimisticDegreeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="节点重要性" prop="nodeImportance">
                    <el-select
                        v-model="form.nodeImportance"
                        placeholder="请选择节点重要性"
                    >
                        <el-option label="请选择" value="0"></el-option>
                        <el-option
                            v-for="item in nodeImportanceList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="接入时间" prop="accessDate" v-if="isCFUser">
                    <el-date-picker
                        v-model="form.accessDate"
                        type="date"
                        placeholder="请选择接入时间">
                    </el-date-picker>
                </el-form-item>
            </div>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import validateMax from "../project-detail-util.js";
import projectDetailService from "#/js/service/projectDetailService.js";
import moment from "moment";

export default {
    name: "add-program-goal",
    props: {
        goalParams: {
            type: Object,
            default: () => {},
        },
        baseData: {
            type: Object,
            default: () => {},
        },
        programGoalTitle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        var validateOptimisticDegree = (rule, value, callback) => {
            if (value === '' || value === '0') {
                callback(new Error('请选择乐观程度'));
            } else {
                callback();
            }
        };
        var validateNodeImportance = (rule, value, callback) => {
            if (value === '' || value === '0') {
                callback(new Error('请选择节点重要性'));
            } else {
                callback();
            }
        };
        return {
            optimisticDegreeList: [
                {
                    value: '1',
                    label: '乐观'
                },
                {
                    value: '2',
                    label: '不乐观'
                },
                {
                    value: '3',
                    label: '谨慎'
                },
            ],
            nodeImportanceList: [
                {
                    value: '1',
                    label: 'A类'
                },
                {
                    value: '2',
                    label: 'B类'
                },
            ],

            dialogFormVisible: false,
            form: {
                monthly: "",
                goalOfRecommandation: "", // 推荐
                goalOfInterview: "", // 面试
                goalOfOffer: "",

                headCount: "", // HC数量
                optimisticDegree: "", // 乐观程度
                nodeImportance: "", // 节点重要性
                accessDate: "", // 接入时间
            },
            rules: {
                monthly: [
                    { required: true, message: "请选择日期", trigger: "blur" },
                ],
                goalOfRecommandation: [
                    {
                        required: true,
                        message: "请输入月推荐交付目标",
                        trigger: "blur",
                    },
                    { validator: validateMax, trigger: "blur" },
                ],
                goalOfInterview: [
                    {
                        required: true,
                        message: "请输入月面试交付目标",
                        trigger: "blur",
                    },
                    { validator: validateMax, trigger: "blur" },
                ],
                goalOfOffer: [
                    {
                        required: true,
                        message: "请输入月offers交付目标",
                        trigger: "blur",
                    },
                    { validator: validateMax, trigger: "blur" },
                ],

                headCount: [
                    {
                        required: true,
                        message: "请输入招聘人数",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: `请输入数字`,
                        trigger: "blur",
                    },
                    {
                        validator: (rules, value, callback) => {
                            if (value && value > 10000) {
                                callback(new Error("招聘人数不能超过10000"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                optimisticDegree: [
                    // { required: true, message: "请选择乐观程度", trigger: "change" },
                    { required: true, validator: validateOptimisticDegree, trigger: 'change' }
                ],
                nodeImportance: [
                    // { required: true, message: "请选择节点重要性", trigger: "change" },
                    { required: true, validator: validateNodeImportance, trigger: 'change' }
                ],
                accessDate: [
                    { required: true, message: "请选择接入时间", trigger: "change" },
                ],
            },

            title: "",
            hasGoalMonth: null, // 有月份的数据
        };
    },
    computed: {
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser;
        },

        pickerOptions() {
            let _this = this;
            return {
                disabledDate(time) {
                    //此处可以根据_this获取到data中定义的数据
                    const _now = moment().format("YYYY-MM");
                    const _time = moment(time).format("YYYY-MM");

                    if (moment(_time).isBefore(_now)) {
                        return true;
                    } else {
                        if (_this.hasGoalMonth) {
                            const _year = moment(time).year();
                            const _month = moment(time).month() + 1;

                            const _months = _this.hasGoalMonth[_year];
                            if (_months) {
                                return _months.includes(_month);
                            }
                            return false;
                        }
                        return false;
                    }
                },
                onPick({ maxDate, minDate }) {},
            };
        },

        businessType() {
            return this.goalParams.businessType;
        },
    },
    created() {
        console.log(this.baseData);
        console.log(this.goalParams);
        this.getFutureMonth();

        // this.form = this.goalParams;

        // 编辑交付目标
        if(this.goalParams.type == "edit") {
            this.form.id = this.goalParams.id;
            this.form.monthly = this.goalParams.monthly;
            this.form.goalOfRecommandation = this.goalParams.goalOfRecommandation;
            this.form.goalOfInterview = this.goalParams.goalOfInterview;
            this.form.goalOfOffer = this.goalParams.goalOfOffer;
        }
        this.form.projectName = this.goalParams.projectName;
        this.form.type = this.goalParams.type;
        this.form.porjectId = this.goalParams.porjectId;

        // this.form.headCount = this.baseData.headcount;
        // this.form.optimisticDegree = this.baseData.optimisticDegree.toString();
        // this.form.nodeImportance = this.baseData.nodeImportance.toString();
        // this.form.accessDate = this.baseData.accessDate;

        if (this.businessType == "project") { // 项目交付目标
            if(this.goalParams.type === "add") {
                this.form.headCount = this.baseData.headcount;
                this.form.optimisticDegree = this.baseData.optimisticDegree.toString();
                this.form.nodeImportance = this.baseData.nodeImportance.toString();
                this.form.accessDate = this.baseData.accessDate;
            }
        
            this.title =
                this.goalParams.type === "edit"
                    ? "编辑项目交付目标"
                    : "添加项目交付目标";
        } else { // 个人交付目标
            this.title =
                this.goalParams.type === "edit"
                    ? "编辑个人交付目标"
                    : "添加个人交付目标";
        }

        console.log(this.form)

    },
    mounted() {},
    methods: {
        getFutureMonth() {
            const params = { projectId: this.goalParams.porjectId };
            let requestFuture;

            if (this.businessType == "project") {
                requestFuture =
                    projectDetailService.getPorjectFutureMonths(params);
            } else {
                requestFuture = projectDetailService.getPersonalFutureMonths();
            }

            requestFuture.then((res) => {
                this.hasGoalMonth = res;
            });
        },

        handleClose() {
            this.$emit("add-goal-finish");
        },

        // 提交
        handleConfirm() {
            this.$refs["goalForm"].validate((valid) => {
                if (valid) {
                    const params = {
                        ...this.form,
                        goalOfOffer: +this.form.goalOfOffer,
                        goalOfInterview: +this.form.goalOfInterview,
                        goalOfRecommandation: +this.form.goalOfRecommandation,
                        monthly: moment(this.form.monthly).format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                        accessDate: moment(this.form.accessDate).format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                    };
                    if(this.businessType != "project" || this.goalParams.type === "edit") {
                        delete params.headCount;
                        delete params.optimisticDegree;
                        delete params.nodeImportance;
                        delete params.accessDate;
                    }
                    if(!this.isCFUser) {
                        delete params.accessDate;
                    }
                    // const successTip =
                    //     this.form.type == "add"
                    //         ? `交付目标添加成功!`
                    //         : `交付目标编辑成功!`;
                    const successTip =
                        this.goalParams.type == "add"
                            ? `交付目标添加成功!`
                            : `交付目标编辑成功!`;
                    const loading = this.$loading({
                        lock: true,
                        text: "页面加载中~",
                        background: "rgba(255, 255, 255, 0.7)",
                    });

                    console.log(params);
                    console.log(this.businessType);

                    let confirmRequest;
                    if (this.businessType == "project") { // 项目交付目标
                        confirmRequest =
                            projectDetailService.addOrUpdateProjectGoal(params);
                    } else { // 个人交付目标
                        confirmRequest =
                            projectDetailService.addOrUpdatePersonalGoal(params);
                    }

                    confirmRequest
                        .then((res) => {
                            shortTips(successTip);
                            this.$emit("add-goal-finish", true);
                        })
                        .finally(() => {
                            loading.close();
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss" scope>
.add-program-goal {
    .header-title {
        font-size: 18px;
    }
    div,
    h2 {
        font-family: "Microsoft YaHei";
    }
    .ell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .sub-title {
        height: 51px;
        background: #f5f5f5;
        border-radius: 2px;
        font-size: 14px;

        font-weight: 400;
        color: #333333;
        line-height: 51px;
        padding-left: 27px;
        margin-bottom: 20px;
    }
    h2 {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 36px;
        margin: 0;
        &::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 20px;
            background: #38bc9d;
            margin-right: 10px;
            vertical-align: middle;
        }
    }
    .form-wrap {
        border: 1px solid #eeeeee;
        padding-top: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
        .el-select {
            width: 384px;
        }
        .el-date-editor.el-input {
            width: 384px;
        }
    }
    .input-cell {
        width: 384px !important;
        .el-input__inner {
            width: 384px;
        }
    }
}
</style>