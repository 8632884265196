const validateMax = (rule, value, callback) => {
    try{
        if(parseInt(+value) >= 0 && parseInt(+value) <= 10000){
            if(!(/(^[0-9]\d*$)/.test(+value))) {
                callback(new Error('输入的不是整数!'));
            } else {
                callback();
            }
        } else {
            callback(new Error('请输入0到10000之间的整数！'));
        }
    } catch (e) {
        callback(new Error('输入数据格式不对！'));
    }
};
export default validateMax;